<script setup lang="tsx">
import * as Chartist from 'chartist'
import { ref, onBeforeMount, onMounted, watch } from 'vue'
import http from '@/services/http'
import type { Response } from '@/models/api-generated'
import { drawLines } from '@/components/donut';

interface Model {
  i?: number
  pi?: number
  ni?: number
  wna?: number
  na?: number
}

const props = defineProps({
  label: String
})

const chart = ref(null)
const result = ref<Model>()

const opts: Chartist.PieChartOptions = {
  donut: true,
  donutWidth: 40,
  labelOffset: 48,
  startAngle: 45,
  showLabel: true,
  labelInterpolationFnc: a => a ? a + '%' : null
}

let pieChart: Chartist.PieChart

onBeforeMount(async () => {
  const { data } = await http.get<Response<Model>>("api/dashboard/overallStatus")
  result.value = data.data;
})

onMounted(() => {
  pieChart = new Chartist.PieChart(chart.value, { series: [] }, opts)
  drawLines(pieChart)
})

watch(result, (data, _) => {
  if (!data) return

  if (!Object.keys(data).length) {
    data.wna = 100
  }

  const series = []
  if (data.i) series.push({ className: 'pie1', value: data.i })
  if (data.pi) series.push({ className: 'pie2', value: data.pi })
  if (data.ni) series.push({ className: 'pie3', value: data.ni })
  if (data.wna) series.push({ className: 'pie4', value: data.wna })
  if (data.na) series.push({ className: 'pie6', value: data.na })
  pieChart.update({ series })
})
</script>

<template>
  <div class="d-flex justify-content-center align-items-center">
    <div ref="chart" class="chart"></div>
    <div v-if="result" class="label">
      <h6 class="chart-content">{{ props.label }}</h6>
    </div>
    <div v-if="!result" class="load">
      <div class="spinner-border text-primary"></div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.load, .label
  position: absolute
.chart
  height: 300px
</style>