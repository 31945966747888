<script setup lang="tsx">
import { onBeforeMount, ref, watch } from 'vue'

import Modal from '../shared/Modal.vue'
import http from '@/services/http'

enum SecurityMethod {
	Main = 'main',
	Standard = 'standard',
}

const props = defineProps({
	disabled: Boolean,
})
const model = defineModel<SecurityMethod>({ default: SecurityMethod.Main })
const isDialogVisible = ref(false)
const options = Object.values(SecurityMethod)

watch(model, (value) => {
	isDialogVisible.value = true
})

async function confirm() {
	await http.post<Request>('api/security-method', { value: model.value })
	window.location.reload()
}

let orig: SecurityMethod
onBeforeMount(() => {
	orig = model.value
})
</script>

<template>
	<div class="mb-3">
		<label for="security-method" class="form-label">{{ $t('securityMethod.title') }}</label>
		<select v-model="model" id="security-method" class="form-select" :disabled="props.disabled">
			<option v-for="option in options" :value="option">
				{{ $t(`securityMethod.${option}`) }}
			</option>
		</select>
	</div>

	<Modal v-model="isDialogVisible" @confirm="confirm" @cancel="model = orig">
		<template #header>
			<h5>{{ $t('securityMethod.dialog.title') }}</h5>
		</template>
		<template #body>
			<p>{{ $t('securityMethod.dialog.body1') }}</p>
			<p>{{ $t('securityMethod.dialog.body2') }}</p>
		</template>
	</Modal>
</template>
