<script setup lang="tsx">
import { onBeforeMount, ref, watch } from 'vue'
import http from '@/services/http'
import type { Response, JiraData } from '@/models/api-generated'
import { useCheckboxStore } from '@/stores/methodCheckboxState'

import { Modal } from 'bootstrap'

const showModal = ref(false)
let modalInstance: Modal | null = null

const closeModal = () => {
	showModal.value = false
	if (modalInstance) {
		modalInstance.hide()
	}
}

const progress = ref(0)
const taskInProgress = ref(false)
const taskCompleted = ref(false)
const model = ref<JiraData>()

const store = useCheckboxStore()

onBeforeMount(async () => {
	const { data } = await http.get<Response<JiraData>>('/api/jira/methodAmount')
	model.value = data.data
})

const startTask = async (IDs: number[] | undefined) => {
	taskInProgress.value = true
	progress.value = 0
	const response = await http.get(`/api/jira/startTask?smmdIDs=${IDs}`)
	if (response.data.status === 'OK') {
		taskInProgress.value = false
		taskCompleted.value = true
	}
}

const stopTask = async () => {
	taskInProgress.value = false
	await http.get(`/api/jira/stopTask`)
}

const importIssues = async () => {
	taskInProgress.value = true
	const response = await http.get(`/api/jira/importIssues`)
	if (response.data.status === 'OK') {
		taskInProgress.value = false
		taskCompleted.value = true
	}
}

watch(showModal, (newValue) => {
	if (newValue) {
		const modalElement = document.getElementById('modal')!
		modalInstance = new Modal(modalElement, {
			backdrop: 'static',
			keyboard: false,
		})
		modalInstance.show()

		modalElement.addEventListener('hidden.bs.modal', () => {
			showModal.value = false
		})
	}
})
</script>

<template>
	<!--button @click="showModal = true" class="btn btn-primary">Jira</button-->
	<div class="modal fade" id="modal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title">Jira</h3>
					<button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
				</div>
				<div class="modal-body">
					<p>{{ $t('jira.exportCybsisMeasuresIntoJira') }}</p>
					<div v-if="taskInProgress" class="loading-container">
						<p>{{ $t('jira.finishedTask') }}</p>
						<div class="spinner"></div>
					</div>
					<div v-else>
						<p v-if="taskCompleted">{{ $t('jira.finishedTask') }}</p>
					</div>
				</div>
				<div class="modal-footer">
					<button
						@click="startTask(store.selectedCheckboxIds)"
						:disabled="taskInProgress || store.selectedCheckboxIds.length === 0"
						class="btn btn-primary"
					>
						{{ $t('jira.startSelected') }}: {{ store.selectedCheckboxIds.length }}
					</button>
					<button @click="startTask(model?.standard_method_meta_data_ids)" :disabled="taskInProgress" class="btn btn-primary">
						{{ $t('jira.startAll') }}: {{ model?.total_count }}
					</button>
					<button @click="stopTask" :disabled="!taskInProgress" class="btn btn-secondary">
						{{ $t('jira.stop') }}
					</button>
					<button @click="importIssues" :disabled="taskInProgress" class="btn btn-secondary">Import</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="sass">
.loading-container
	display: flex
	justify-content: center
	align-items: center
	margin: 20px 0


.spinner
	border: 8px solid #f3f3f3
	border-top: 8px solid #3498db
	border-radius: 50%
	width: 60px
	height: 60px
	animation: spin 2s linear infinite


@keyframes spin
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
</style>
