<script setup lang="tsx">
import { BasicAuditor, InternalAuditor, NotAuditor, type AuditorLevel } from '@/models/enums-generated'
import { onBeforeMount } from 'vue'

const props = defineProps({
	name: String,
	value: String,
})

const strings = {
	et: {
		label: 'Audiitori tase',
	},
	en: {
		label: 'Auditor level',
	},
}

const options = [
	{
		value: NotAuditor,
		text: { et: 'Ei ole audiitor', en: 'Not an auditor' },
	},
	{
		value: BasicAuditor,
		text: { et: 'Audiitor', en: 'Auditor' },
	},
	{
		value: InternalAuditor,
		text: { et: 'Siseaudiitor', en: 'Internal auditor' },
	},
]

const model = defineModel<AuditorLevel>({ default: NotAuditor })

let idCounter = 0
let label: string

onBeforeMount(() => {
	label = `auditor-level-select-${idCounter++}`

	if (props.value !== undefined) {
		model.value = Number.parseInt(props.value)
	}
})
</script>

<template>
	<label :for="label" class="form-label">{{ strings.en.label }}</label>
	<select class="form-control form-control-sm" data-type="number" sad="panda" :id="label" :name="props.name" v-model="model">
		<option v-for="opt of options" :value="opt.value">{{ opt.text.en }}</option>
	</select>
</template>
