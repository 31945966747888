<script setup lang="tsx">
import { ref, onBeforeMount  } from 'vue'
import http from '@/services/http'

// Neid hakkame auto-genereerima Go failidest
interface Model {
    id: number
    name: string
}

const result = ref<Model>()

onBeforeMount(async () => {
  const { data } = await http.get<Model>("https://api.restful-api.dev/objects/7")

  result.value = data;
})

</script>

<template>
  <div v-if="!result">mõtleb...</div>
  <div v-else>{{ JSON.stringify(result) }}</div>
  <br />
  <div hx-get="fragment.html" hx-trigger="mouseenter once">
    hover me
  </div>
</template>
