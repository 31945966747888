<script setup lang="tsx">
import { ref, onBeforeMount } from 'vue'
import http from '@/services/http'
import type { Response } from '@/models/api-generated'

interface ProgressByModule {
  [module: string]: {
    high?: Model;
    standard?: Model;
    main?: Model;
  }
}

interface Model {
  i?: number
  pi?: number
  ni?: number
  wna?: number
  na?: number
}

const props = defineProps({
  h: String,
  s: String,
  m: String
})

const result = ref<ProgressByModule>()

onBeforeMount(async () => {
  const { data } = await http.get<Response<ProgressByModule>>("api/dashboard/progress")
  result.value = data.data;
})

function style(a?: number) {
  if (!a) a = 0
  return 'flex-basis:' + a + '%;'
}
</script>

<template>
  <div class="container">
    <div v-if="result">
      <div class="d-flex">
        <h4 class="align-self-center me-4 mb-0 flex55">
          <span class="badge badge-outline w-100">{{ props.h }}</span>
        </h4>
        <div class="columns w-100">
          <div v-for="val in Object.values(result)" class="column-container">
            <div class="column-bar series-i" :style="style(val.high?.i)"></div>
            <div class="column-bar series-pi" :style="style(val.high?.pi)"></div>
            <div class="column-bar series-wna" :style="style(val.high?.wna)"></div>
            <div class="column-bar series-ni" :style="style(val.high?.ni)"></div>
            <div class="column-bar series-na" :style="style(val.high?.na)"></div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <h4 class="align-self-center me-4 mb-0 flex55">
          <span class="badge badge-outline w-100">{{ props.s }}</span>
        </h4>
        <div class="columns w-100">
          <div v-for="val in Object.values(result)" class="column-container">
            <div class="column-bar series-i" :style="style(val.standard?.i)"></div>
            <div class="column-bar series-pi" :style="style(val.standard?.pi)"></div>
            <div class="column-bar series-wna" :style="style(val.standard?.wna)"></div>
            <div class="column-bar series-ni" :style="style(val.standard?.ni)"></div>
            <div class="column-bar series-na" :style="style(val.standard?.na)"></div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <h4 class="align-self-center me-4 mb-0 flex55">
          <span class="badge badge-outline w-100">{{ props.m }}</span>
        </h4>
        <div class="columns w-100">
          <div v-for="val in Object.values(result)" class="column-container">
            <div class="column-bar series-i" :style="style(val.main?.i)"></div>
            <div class="column-bar series-pi" :style="style(val.main?.pi)"></div>
            <div class="column-bar series-wna" :style="style(val.main?.wna)"></div>
            <div class="column-bar series-ni" :style="style(val.main?.ni)"></div>
            <div class="column-bar series-na" :style="style(val.main?.na)"></div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="align-self-center me-4 mb-0 flex55"></div>
        <div class="columns w-100 legend">
          <div v-for="moduleName in Object.keys(result)" class="column-container">
            <div class="column-bar">{{ moduleName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <div class="spinner-border text-primary"></div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.container
  height: 330px
.flex55
  flex: 0 0 55px
.legend
  height: 30px
</style>