<script setup lang="tsx">
import type { Response, User } from '@/models/api-generated'
import { nextTick, onBeforeMount, ref, watch } from 'vue'

import Autocomplete, { type Config } from 'bootstrap5-autocomplete'
import http from '@/services/http'

const props = defineProps({
	id: String,
	disabled: Boolean,
})

const model = defineModel<User>()
const elementRef = ref<HTMLInputElement>()
let instance: Autocomplete | undefined

watch(model, (m) => {
	nextTick(() => {
		if (instance?._searchInput) instance._searchInput.value = m?.label ?? ''
	})
})

watch(elementRef, (el) => {
	if (el) {
		if (instance) instance.dispose()

		instance = new Autocomplete(el, {
			queryParam: 'q',
			searchFields: ['firstname', 'lastname'],
			labelField: 'label',
			valueField: 'id',
			server: 'api/users',
			serverDataKey: 'data',
			liveServer: true,
			//hiddenInput: true,
			//hiddenValue: model.value?.id.toString() ?? '',
			//updateOnSelect: true,
			suggestionsThreshold: 0,
			onSelectItem: (item: User, inst: Autocomplete) => {
				model.value = item
			},
			onChange: (item: User | undefined, inst: Autocomplete) => {
				model.value = item
			},
		})

		nextTick(() => {
			if (instance?._searchInput) instance._searchInput.value = model.value?.label ?? ''
		})
	}
})
</script>

<template>
	<input :id="props.id" :disabled="props.disabled" ref="elementRef" type="search" class="form-control" />
</template>

<style></style>
