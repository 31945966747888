<script setup lang="tsx">
import { defineEmits, ref, watch } from 'vue'

const props = defineProps<{
	totalPages: number
}>()

const model = defineModel<number>({ default: 1 })

function goToPage(page: number) {
	if (page >= 1 && page <= props.totalPages && page !== model.value) {
		model.value = page
	}
}
</script>

<template>
	<div class="pagination-container">
		<button class="pagination-button" :class="{ 'pagination-disabled': model === totalPages }" @click="goToPage(1)" :disabled="model === 1">
			{{ $t('first') }}
		</button>
		<button
			class="pagination-button"
			:class="{ 'pagination-disabled': model === totalPages }"
			@click="goToPage(model - 1)"
			:disabled="model === 1"
		>
			{{ $t('previous') }}
		</button>

		<span>{{ model }} / {{ totalPages }}</span>

		<button
			class="pagination-button"
			:class="{ 'pagination-disabled': model === totalPages }"
			@click="goToPage(model + 1)"
			:disabled="model === totalPages"
		>
			{{ $t('next') }}
		</button>
		<button
			class="pagination-button"
			:class="{ 'pagination-disabled': model === totalPages }"
			@click="goToPage(totalPages)"
			:disabled="model === totalPages"
		>
			{{ $t('last') }}
		</button>
	</div>
</template>

<style scoped>
.pagination-container {
	align-items: center;
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.pagination-button {
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	margin: 0 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}
.pagination-button:hover {
	background-color: #0056b3;
}
.pagination-button:disabled {
	background-color: #ced4da;
	cursor: not-allowed;
}
</style>
