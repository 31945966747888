<script setup lang="tsx">
import { ref, onBeforeMount, onMounted, watch } from 'vue'
import http from '@/services/http'
import type { ApproachingDueDate, Response } from '@/models/api-generated'

const props = defineProps({
  day: String,
  days: String,
  today: String,
  overdue: String
})

const result = ref<ApproachingDueDate[]>()

onBeforeMount(async () => {
  const { data } = await http.get<Response<ApproachingDueDate[]>>("api/dashboard/approachingDueDate")
  result.value = data.data;
})
</script>

<template>
  <div v-if="result">
    <div v-for="row in result" :class="'row mt-2 ' + row.class">
      <div class="col-md">
        <div class="row">
          <div class="col-3">
            {{ row.name }}
          </div>
          <div class="col-6">
            {{ row.responsible }}
          </div>
          <div class="col-3">
            {{ row.dueDate }}
          </div>
        </div>
        <div class="row small">
          <div class="col">
            {{ row.instance }}
          </div>
          <div class="col-3">
            <span v-if="row.days < 0" class="fw-bold">{{ props.overdue }}</span>
            <span v-else-if="row.days === 0" class="fw-bold">{{ props.today }}</span>
            <span v-else-if="row.days === 1" class="fw-bold">1 {{ props.day }}</span>
            <span v-else>{{ row.days }} {{ props.days }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="p-4 text-center">
    <div class="spinner-border text-primary"></div>
  </div>
</template>

<style lang="sass" scoped>
.small
  font-size: 12px
</style>