<script setup lang="tsx">
import { ref } from 'vue'
import counter from '@/stores/counter'

const myCount = ref(0)
const store = counter()

const simpleJsx = (props: any) => <div>{props.a}: {props.b}</div>

async function inc() {
  myCount.value++
  store.increment()
}
</script>

<template>
  <p>
    <simple-jsx a="My count" :b="myCount" />
    <simple-jsx a="Store count" :b="store.count" />
    <button v-on:click="inc()">+</button>
  </p>
</template>

<style lang="sass" scoped>
p
  color: blue
  font-weight: bold

button
  zoom: 4
  border: 0
</style>