import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useCheckboxStore = defineStore('methodCheckboxState', () => {
	const selectedCheckboxIds = ref<number[]>([])

	function addCheckboxId(smmdID: number) {
		if (!selectedCheckboxIds.value.includes(smmdID)) {
			selectedCheckboxIds.value.push(smmdID)
		}
	}

	function removeCheckboxId(smmdID: number) {
		selectedCheckboxIds.value = selectedCheckboxIds.value.filter((existingId) => existingId !== smmdID)
	}

	function resetCheckboxIds() {
		selectedCheckboxIds.value = []
	}

	return {
		selectedCheckboxIds,
		addCheckboxId,
		removeCheckboxId,
		resetCheckboxIds,
	}
})
