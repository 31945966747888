import { createI18n } from 'vue-i18n'
import et from '@/locales/et'
import en from '@/locales/en'

const i18n = createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: { en, et },
	legacy: false,
})

export function setI18nLanguage(language: 'et' | 'en') {
	i18n.global.locale.value = language
}
export default i18n
