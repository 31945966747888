import axios from 'axios';
import type { CreateAxiosDefaults, AxiosInterceptorOptions } from 'axios';

const config: CreateAxiosDefaults = { }
const http = axios.create(config)
export default http;

const options: AxiosInterceptorOptions = { }

// Implement global error handling
http.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  },
  options
)
