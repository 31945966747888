<script setup lang="tsx">
import { onBeforeMount, ref, watch } from 'vue'

const props = defineProps({
	id: String,
	disabled: Boolean,
})
const model = defineModel<String>()
const iso = ref<String>()

watch(model, toIso)
watch(iso, toModel)

function toModel() {
	if (iso.value) {
		const [year, month, day] = iso.value.split('-')
		model.value = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
	} else {
		model.value = undefined
	}
}

function toIso() {
	if (model.value) {
		const [day, month, year] = model.value.split('.')
		iso.value = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
	} else {
		iso.value = undefined
	}
}

onBeforeMount(toIso)
</script>

<template>
	<input :id="props.id" :disabled="props.disabled" type="date" class="form-control" v-model="iso" />
</template>
