import { createApp, compile } from 'vue'
import { createPinia } from 'pinia'
import i18n, { setI18nLanguage } from '@/i18n'

import TestWidget from '@/components/TestWidget.vue'
import ApiWidget from '@/components/ApiWidget.vue'
import OverallStatusChart from '@/components/dashboard/OverallStatusChart.vue'
import ProgressChart from '@/components/dashboard/ProgressChart.vue'
import ApproachingDueDate from '@/components/dashboard/ApproachingDueDate.vue'
import DueDateMethods from '@/components/dashboard/DueDateMethods.vue'
import AuditorLevelSelect from '@/components/user/AuditorLevelSelect.vue'
import EditMethodsBtn from '@/components/methods/EditMethodsBtn.vue'
import EditMethodsToolbar from '@/components/methods/EditMethodsToolbar.vue'
import MethodTable from '@/components/methods/MethodTable.vue'

import JiraExport from '@/components/jira/JiraExport.vue'
import SecurityMethod from '@/components/business_processes/SecurityMethod.vue'

import * as style from '@/styles/main.sass'
import * as bootstrap from 'bootstrap'
import MassEditMethodsBtn from './components/methods/MassEditMethodsBtn.vue'

// Shared state between components
const pinia = createPinia()

const createVue = () =>
	createApp({})
		.use(pinia)
		.use(i18n)

		// Component registration (only required for global availability)
		.component('apiWidget', ApiWidget)
		.component('testWidget', TestWidget)
		.component('dashboardOverallStatusChart', OverallStatusChart)
		.component('dashboardProgressChart', ProgressChart)
		.component('dashboardApproachingDueDate', ApproachingDueDate)
		.component('dashboardDueDateMethods', DueDateMethods)
		.component('userAuditorLevelSelect', AuditorLevelSelect)
		.component('editMethodsBtn', EditMethodsBtn)
		.component('editMethodsToolbar', EditMethodsToolbar)
		.component('massEditMethodsBtn', MassEditMethodsBtn)
		.component('methodTable', MethodTable)
		.component('jiraExport', JiraExport)
		.component('securityMethod', SecurityMethod)
window.createVue = createVue
window.bootstrap = bootstrap

// Mount main instance
document.addEventListener('DOMContentLoaded', () => {
	const data = window.sessionMap
	setI18nLanguage(data.language)
	createVue().mount('app')
	window.legacyReady()
})
