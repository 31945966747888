<script setup lang="tsx">
import methodEditor from '@/stores/method-editor'

const store = methodEditor()
</script>

<template>
	<button
		v-if="!store.isEditMode"
		type="button"
		class="btn btn-primary"
		:disabled="!store.massEdit.selected.length"
		@click="store.massEdit.visible = true"
	>
		{{ $t('editSelected') }}
		<span v-if="store.massEdit.selected.length"> ({{ store.massEdit.selected.length }}) </span>
	</button>
</template>
