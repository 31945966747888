<script setup lang="tsx">
import http from '@/services/http'
import methodEditor from '@/stores/method-editor'

const editor = methodEditor()

async function cancel() {
	editor.isEditMode = false
}

async function save() {
	const ch = editor.getChanges()

	editor.changeCount = 0
	editor.isEditMode = false

	await http.post('api/editMethodMetaData', ch)
	await editor.refresh()
}
</script>

<template>
	<div v-if="editor.isEditMode" class="container fixed-bottom mb-4">
		<div class="row">
			<div class="col"></div>
			<div class="col text-center">
				<div class="d-inline-flex justify-content-between toolbar text-nowrap">
					<button type="button" class="btn btn-light me-3" @click="cancel">{{ $t('cancel') }}</button>
					<button type="button" class="btn btn-primary" @click="save" :disabled="!editor.canSave">
						{{ $t('save') }}
						<template v-if="editor.changeCount > 0"> ({{ editor.changeCount }})</template>
					</button>
				</div>
			</div>
			<div class="col"></div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.container
	pointer-events: none

	.toolbar
		pointer-events: auto
		background-color: #dde7fa
		padding: 1rem
		border-radius: .5rem
		border: 1px solid #7891bd
</style>
