<script setup lang="tsx">
import { type Priority, MediumPriority, HighPriority, LowPriority } from '@/models/enums-generated'

const props = defineProps({
	id: String,
	disabled: Boolean,
})
const model = defineModel<Priority>()
</script>

<template>
	<select :id="props.id" :disabled="props.disabled" class="form-select" v-model="model">
		<option value="">-</option>
		<option :value="HighPriority">{{ $t('high') }}</option>
		<option :value="MediumPriority">{{ $t('medium') }}</option>
		<option :value="LowPriority">{{ $t('low') }}</option>
	</select>
</template>
