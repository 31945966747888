<script setup lang="tsx">
import PlusButton from '@/components/widgets/PlusButton.vue'
import type { CustomRuleMetaData, StandardRuleMetaData } from '@/models/api-generated'
import methodEditor from '@/stores/method-editor'
import { computed, onBeforeMount, ref, watch } from 'vue'
import RuleStatusIcon from '@/components/widgets/RuleStatusIcon.vue'
import RuleStatusSelect from '@/components/widgets/RuleStatusSelect.vue'
import SubRuleRow from './SubRuleRow.vue'
import { type RuleStatus, RuleWillNotApply } from '@/models/enums-generated'

const props = defineProps({
	isReadOnly: Boolean,
})
const model = defineModel<StandardRuleMetaData | CustomRuleMetaData>({ required: true })
const isExpanded = ref(false)
const isEditMode = computed(() => !props.isReadOnly && editor.isEditMode)
const editor = methodEditor()
editor.watchRule(model.value)

function isValid(str: string) {
	return model.value.status !== RuleWillNotApply || str
}

onBeforeMount(() => {
	if (model.value.sub_rules.length) {
		isExpanded.value = true

		watch(
			() => model.value.status,
			(status: RuleStatus) => {
				for (let i = 0; i < model.value.sub_rules.length; i++) {
					model.value.sub_rules[i].status = status
				}
			},
		)
	}
})
</script>

<template>
	<div class="row">
		<div class="col-6 d-inline-flex align-items-center">
			<div v-if="model.sub_rules.length > 0">
				<PlusButton v-model="isExpanded"></PlusButton>
			</div>
			<div v-else>
				<i class="far fa-plus-square table-cell-icon" style="color: lightgray"></i>
			</div>
			<div>
				{{ model.rule.value }}
			</div>
		</div>

		<div class="col align-self-center text-center">
			<RuleStatusIcon v-if="!isEditMode" v-model="model.status"></RuleStatusIcon>
			<RuleStatusSelect v-else v-model="model.status" class="w-50 d-inline"></RuleStatusSelect>
		</div>
	</div>
	<div v-if="model.comment || isEditMode" class="row">
		<div class="col">
			<label :for="'method-comment' + model.id" class="form-label">{{ $t('comment') }}:</label>
			<div v-if="!isEditMode" class="comment m-0" rows="1">{{ model.comment }}</div>
			<input
				v-else
				type="text"
				:id="'method-comment' + model.id"
				class="form-control"
				:class="{ 'is-invalid': !isValid(model.comment) }"
				v-model="model.comment"
			/>
		</div>
	</div>
	<SubRuleRow v-if="isExpanded" v-model="model"></SubRuleRow>
</template>

<style lang="sass" scoped>
.comment
	border-radius: .25rem
	background-color: rgb(248, 248, 248)
	padding: .5rem
</style>
