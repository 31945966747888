<script setup lang="tsx">
const props = defineProps({
	id: String,
	class: String,
	disabled: Boolean,
	min: Number,
	max: Number,
})
const model = defineModel<number>({ required: true })

function fromString(str: string): number {
	const a = parseFloat(str)
	if (Number.isFinite(a)) return a
	return 0
}

function toString(num: number): string {
	if (Number.isFinite(num)) return num.toString()
	return ''
}

function change(e: Event) {
	const target = e.target as HTMLInputElement
	model.value = fromString(target.value)
	target.value = toString(model.value)
}
</script>

<template>
	<input :id="props.id" :class="props.class" :disabled="props.disabled" :value="model?.toString()" @change="change" />
</template>
