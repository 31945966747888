const et = {
	jira: {
		stop: 'Lõpeta',
		startAll: 'Ekspordi kõik',
		startSelected: 'Ekspordi valitud meetmed',
		exportCybsisMeasuresIntoJira: 'Ekspordi Cybsise meetmed Jirasse',
		amountOfMeasuresToExport: 'Meetmete arv, mida eksportida Jirasse',
		finishedTask: 'Tehing lõpetatud',
	},
	implemented: 'Rakendatud',
	partiallyImplemented: 'Osaliselt rakendatud',
	needsAttention: 'Vajab tähelepanu',
	notComplete: 'Rakendamata',
	byModules: 'Moodulite kaupa',
	normal: 'Normaalne',
	big: 'Suur',
	veryBig: 'Väga suur',
	businessProcesses: 'Äriprotsessid',
	securityNeed: 'Kaitsetarve',
	overallStatus: 'Üldine seisunud',
	of: '-',
	measures: 'Meetmed',
	name: 'Nimi',
	active: 'Aktiivne',
	notActive: 'Mitte aktiivne',
	price: 'Hind',
	comment: 'Kommentaar',
	cancel: 'Tühista',
	create: 'Lisa',
	editSelected: 'Muuda valitud',
	details: 'Üksikasjad',
	history: 'Ajalugu',
	connections: 'Seotud objektid',
	moduleConnections: 'Seotud moodulid',
	businessProcessesConnections: 'Seotud äriprotsesside',
	assetsConnections: 'Seotud varad',
	connection: 'Seos',
	add: 'Lisa',
	edit: 'Muuda',
	delete: 'Kustuta',
	view: 'Vaata',
	save: 'Salvesta',
	chooseConnection: 'Vali seos',
	confidentiality: 'Konfidentsiaalsus',
	availability: 'Käideldavus',
	integrity: 'Terviklikkus',
	assetName: 'Vara nimi',
	assetComment: 'Vara kommentaar',
	assetStatus: 'Vara staatus',
	dataAsset: 'Infovara',
	module: 'Moodul',
	moduleTitle: 'Mooduli pealkiri',
	methodDescription: 'Meetodi kirjeldus',
	methodComment: 'Meetodi kommentaar',
	reference: 'Viide',
	title: 'Pealkiri',
	responsible: 'Vastutav',
	priority: 'Prioriteet',
	status: 'Staatus',
	notImplemented: 'Rakendamata',
	rules: 'Reeglid',
	subrule: 'Alamreegel',
	businessProcessReferences: 'Äriprotsessi viited',
	rule: 'Reegel',
	low: 'Madal',
	medium: 'Keskmine',
	high: 'Kõrge',
	choose: 'Vali ...',
	editMeasure: 'Meetme muutmine',
	lowShort: 'madal',
	mediumShort: 'keskmine',
	highShort: 'kõrge',
	main: 'Põhi',
	standard: 'Standard',
	securityNeedMatrix: 'Kaitsetarbe maatriks',
	modules: 'Moodulid',
	relatedAssets: 'Seotud varad',
	applicableMeasures: 'Rakendatavad meetmed',
	applicationDescription: 'Rakendus/Kirjeldus',
	assetReference: 'Vara viide',
	vb: 'VS',
	b: 'S',
	n: 'K',
	confirmation: 'Kinnitus',
	confirm: 'Kinnita',
	accessRights: 'Juurdepääsuõigused',
	general: 'Üldine',
	responsibleShort: 'Vastutav ...',
	willNotApply: 'Ei rakendata',
	areYouCertainYouDoNotWantToApplyThisMeasure: 'Kas olete kindel, et ei soovi seda meedet rakendada?',
	pleaseWriteExplanationWhyThisMeasureCannotBeApplied: 'Palun kirjutage selgitus, miks seda meedet ei saa rakendada.',
	code: 'Kood',
	description: 'Kirjeldus',
	relatedModule: 'Seotud moodul',
	moduleDescription: 'Mooduli kirjeldus',
	addExistingMeasure: 'Lisa olemasolev meede',
	addNewMeasure: 'Lisa uus meede',
	chooseModule: 'Vali moodul',
	chooseMeasure: 'Vali meede',
	chooseAsset: 'Vali vara',
	measureIsRequired: 'Meede on kohustuslik',
	assetIsRequired: 'Vara on kohustuslik',
	moduleIsRequired: 'Moodul on kohustuslik',
	pleaseSelectModuleFirst: 'Palun valige moodul.',
	pleaseSelectAssetFirst: 'Palun valige vara.',
	h: 'K',
	s: 'S',
	m: 'P',
	core: 'Tuumik',
	areYouCertainYouWantToChangeTheSecurityMethod: 'Kas olete kindel, et soovite turbeviisi muuta?',
	thisWillChangeTheListOfMethodsNeededToBeAppliedDependingOnTheMethodSelected:
		'See muudab rakendatavate meetmete nimekirja vastavalt valitud turbeviisile.',
	measuresFound: 'Leitud meetmete arv',
	appliedUserRights: 'Rakendatud kasutajaõigused',
	none: 'Puudub',
	own: 'Enda',
	all: 'Kõik',
	userRights: 'Kasutajaõigused',
	addSubject: 'Lisa õigus',
	moduleInfo: 'Mooduli informatsioon',
	editMethod: 'Meetodi muutmine',
	chooseBusinessProcess: 'Vali äriprotsess',
	owner: 'Omanik',
	contactResponsible: 'Kontakt/vastutav',
	close: 'Sulge',
	associations: 'Seoseid',
	association: 'Seos',
	allBusinessProcesses: 'Kõik äriprotsessid',
	commentIsRequiredWithWillNotApplyStatus: 'Mitte rakendamise korral on selgitus nõutud.',
	assetAssociations: 'Vara seosed',
	noConnectedAssets: 'Seotud varad puuduvad',
	associationName: 'Seose nimetus',
	methods: 'Meetmed',
	userHasFullAccess: 'Kasutajal on piiramata juurdepääs',
	thisOperationWillOverrideDataForAllTheMethodsSelected: 'SNB! See toiming kirjutab kõikide valitud meetodite andmed üle!',
	saveSelectedMethods: 'Salvesta valitud meetmed',
	saveMethods: 'Salvesta meetmed',
	methodWasAddedManuallyOutsideOfAssociationRules: 'Meetod lisati seoste reeglite väliselt',
	remove: 'Eemalda',
	areYouCertainYouWantToRemoveThisMeasure: 'Kas olete kindel, et soovite antud meedet eemaldada?',
	pleaseSelectBusinessProcessFirst: 'Palun valige äriprotsess.',
	custom: 'Kohandatud',
	yes: 'Jah',
	no: 'Ei',
	institutions: 'Asutused',
	currentlyViewing: 'Valitud asutus',
	institution: 'Asutus',
	dueDate: 'Tähtaeg',
	dueDateIn: 'Tähtajani',
	day: 'päev',
	days: 'päeva',
	overdue: 'Aegunud',
	today: 'Täna',
	changesDetected: 'Salvestamata muudatused',
	thereAreUnsavedChangesInTheCurrentlyOpenForm:
		' Hetkel lahti olevas vormis on salvestamata muudatused. Kas olete kindel, et soovite muudatused hüljata?',
	now: 'Nüüd',
	methodEditedSuccessfully: 'Meetod edukalt muudetud',
	methodsEditedSuccessfully: 'Meetodid edukalt muudetud',
	customMethodRemovedSuccessfully: 'Kohandatud meetod edukalt eemaldatud',
	securityMethodChangedSuccessfully: 'Turvameetod edukalt muudetud',
	upgradeCompletedSuccessfully: 'Uuendus edukalt lõpetatud',
	onTime: 'Graafikus',
	approaching: 'Lähenemas',
	critical: 'Kriitiline',
	measuresDueDateStatus: 'Meetmete tähtajani staatused',
	measuresApproachingDueDate: 'Tähtajale lähenevad meetmed',
	relatedDataAssets: 'Seotud infovarad',
	date: 'Kuupäev',
	hasPersonalData: 'Sisaldab isikuandmeid',
	unspecified: 'Määratlemata',
	internalAuditorComment: 'Siseaudiitori kommentaar',
	auditComment: 'Audiitori kommentaar',
	lastInternalAuditorComment: 'Eelmine siseaudiitori kommentaar',
	lastAuditorComment: 'Eelmine audiitori kommentaar',
	addComment: 'Lisa kommentaar',
	previous: 'Eelmine',
	next: 'Järgmine',
	last: 'Viimane',
	first: 'Esimene',
	modal: {
		cancel: 'Tühista',
		confirm: 'Kinnita',
	},
	securityMethod: {
		title: 'Turbeviis',
		main: 'Põhi',
		standard: 'Standard',
		dialog: {
			title: 'Kinnitus',
			body1: 'Kas te olete kindel, et soovite turbeviisi muuta?',
			body2: 'See muudab rakendatavate meetmete nimekirja vastavalt valitud turbeviisile.',
		},
	},
	loseUnsavedChanges: 'Salvestamata muudatused lähevad kaduma!',
	massEditWarning: 'NB! See toiming kirjutab kõikide valitud seoste andmed üle.',
	methodWillNotApply: 'Meedet ei rakendata',
	methodImplemented: 'Rakenda kõik reeglid',
	willNotApplyCommentRequired: 'Nõutud on selgitus miks antud meedet ei rakendata.',
}
export default et
