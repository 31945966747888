import * as Chartist from 'chartist'

export function drawLines(pieChart: Chartist.PieChart) {
	const sliceCenters: DOMPoint[] = []

	pieChart.on('draw', (e) => {
		if (e.type === 'slice') {
			const node = e.element.getNode() as SVGPathElement
			const half = (node.getTotalLength() || 0) / 2
			try {
				const point = node.getPointAtLength(half)
				sliceCenters.push(point)
			} catch {}
		} else if (e.type === 'label') {
			const chart = pieChart as any
			const count = chart.data.series.length
			if (count == 1) {
				e.y = 300
				e.element.getNode().setAttribute('dy', '300')
			}

			const textRect = e.element.getNode().getBoundingClientRect()
			const offsetX = textRect.width / 2
			const offsetY = textRect.height / 2
			const underline = new Chartist.Svg(
				'line',
				{
					x1: e.x - offsetX,
					y1: e.y + offsetY,
					x2: e.x + offsetX,
					y2: e.y + offsetY,
					stroke: 'black',
				},
				undefined,
				e.group,
			)

			const startX = e.x < e.chartRect.width() / 2 ? offsetX : -offsetX
			const end = sliceCenters[e.index]
			const line = new Chartist.Svg(
				'line',
				{
					x1: e.x + startX,
					y1: e.y + offsetY,
					x2: end.x,
					y2: end.y,
					stroke: 'black',
				},
				undefined,
				e.group,
			)
		}
	})
}
