import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import type { ApplicableMethodMetaData, CustomRuleMetaData, EditMethodMetaDataRequest, StandardRuleMetaData } from '@/models/api-generated'
import type { ApplicableMethodExpand } from '@/models/applicable-method'

import i18n from '@/i18n'
import { RuleWillNotApply } from '@/models/enums-generated'

export interface MethodEditModel {
	visible: boolean
	method?: ApplicableMethodExpand
	selected: ApplicableMethodMetaData[]
}

export default defineStore('method-editor', () => {
	const isEditMode = ref(false)
	const canSave = computed(() => {
		if (!changeCount.value) return false

		for (const [_, rule] of standardRules) {
			if (rule.status === RuleWillNotApply && !rule.comment) {
				return false
			}

			for (const subRule of rule.sub_rules) {
				if (subRule.status === RuleWillNotApply && !subRule.comment) {
					return false
				}
			}
		}
		return true
	})
	const massEdit = ref<MethodEditModel>({ visible: false, selected: [] })

	const changeCount = ref(0)

	const standardMethods = new Map<number, ApplicableMethodMetaData>()
	const standardRules = new Map<number, StandardRuleMetaData>()

	const fetch = ref<() => Promise<void>>()

	const refresh = async () => {
		standardMethods.clear()
		standardRules.clear()
		massEdit.value.selected.splice(0, massEdit.value.selected.length)
		changeCount.value = 0

		if (fetch.value) await fetch.value()
	}

	watch(isEditMode, async (newMode, oldMode) => {
		if (oldMode && !newMode && changeCount.value) {
			window.onbeforeunload = null
			const cb = window.confirm(i18n.global.t('loseUnsavedChanges'))
			if (cb) {
				await refresh()
			} else {
				isEditMode.value = true
				return
			}
		}

		const els = [document.querySelector('#export-csv') as HTMLElement, document.querySelector('#add-new-method') as HTMLElement]

		if (newMode) els.forEach((x) => x.classList.add('d-none'))
		else els.forEach((x) => x.classList.remove('d-none'))
	})

	watch(changeCount, (changes) => {
		window.onbeforeunload = changes ? () => i18n.global.t('loseUnsavedChanges') : null
	})

	function count() {
		const u = new Set<number>()
		for (const m of standardMethods.values()) u.add(m.id)
		for (const r of standardRules.values()) u.add(r.method_meta_data_id ?? 0)
		changeCount.value = u.size
	}

	function changeMethod(method: ApplicableMethodMetaData) {
		standardMethods.set(method.id, method)
		count()
	}

	function changeRule(rule: StandardRuleMetaData | CustomRuleMetaData) {
		standardRules.set(rule.id, rule as any)
		count()
	}

	function watchMethod(method: ApplicableMethodMetaData) {
		watch(
			() => method.price,
			() => changeMethod(method),
		)

		watch(
			() => method.priority,
			() => changeMethod(method),
		)

		watch(
			() => method.due_date,
			() => changeMethod(method),
		)

		watch(
			() => method.responsible,
			() => changeMethod(method),
		)

		watch(
			() => method.comment,
			() => changeMethod(method),
		)
	}

	function watchRule(rule: StandardRuleMetaData | CustomRuleMetaData) {
		watch(
			() => rule.status,
			() => changeRule(rule),
		)

		watch(
			() => rule.comment,
			() => changeRule(rule),
		)
	}

	function getChanges(): EditMethodMetaDataRequest {
		return {
			standardMethods: Array.from(standardMethods.values()),
			standardRules: Array.from(standardRules.values()),
		}
	}

	return { isEditMode, massEdit, watchMethod, watchRule, getChanges, changeCount, canSave, refresh, fetch }
})
