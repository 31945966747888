import i18n from '@/i18n'

export function getMethodStatusText(status: string | undefined) {
	switch (status) {
		case 'Partially implemented':
			return i18n.global.t('partiallyImplemented')
		case 'Implemented':
			return i18n.global.t('implemented')
		case 'Will not apply':
			return i18n.global.t('willNotApply')
		case 'Needs attention':
			return i18n.global.t('needsAttention')
		case 'Not implemented':
			return i18n.global.t('notImplemented')
		default:
			return ''
	}
}

export function getRuleStatusText(status: number | undefined) {
	switch (status) {
		case 0:
			return i18n.global.t('notImplemented')
		case 1:
			return i18n.global.t('implemented')
		case 2:
			return i18n.global.t('willNotApply')
		case 3:
			return i18n.global.t('needsAttention')
		default:
			return ''
	}
}
