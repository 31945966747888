<script setup lang="tsx">
import methodEditor from '@/stores/method-editor'
import { ref, onBeforeMount, watch } from 'vue'

const store = methodEditor()
</script>

<template>
	<button
		v-if="!store.isEditMode"
		class="btn btn-primary"
		:aria-pressed="store.isEditMode"
		:class="{ active: store.isEditMode }"
		@click="store.isEditMode = !store.isEditMode"
	>
		<slot></slot>
	</button>
</template>
