<script setup lang="tsx">
const model = defineModel({ default: false })
</script>

<template>
	<tr>
		<i class="far method-expand-icon" :class="{ 'fa-minus-square': model, 'fa-plus-square': !model }" @click="model = !model"></i>
	</tr>
</template>

<style>
.method-expand-icon {
	font-size: 1.1rem !important;
	width: 16px;
	vertical-align: middle;
}
</style>
