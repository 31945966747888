<script setup lang="tsx">
import { RuleNotImplemented, RuleImplemented, RuleWillNotApply, RuleNeedsAttention } from '@/models/enums-generated'

const props = defineProps({
	id: String,
	disabled: Boolean,
})
const model = defineModel({ default: RuleNotImplemented })
</script>

<template>
	<select :id="props.id" :disabled="props.disabled" class="form-select" v-model="model">
		<option :value="RuleNotImplemented">{{ $t('notImplemented') }}</option>
		<option :value="RuleImplemented">{{ $t('implemented') }}</option>
		<option :value="RuleWillNotApply">{{ $t('willNotApply') }}</option>
		<option :value="RuleNeedsAttention">{{ $t('needsAttention') }}</option>
	</select>
</template>
