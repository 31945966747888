<script setup lang="tsx">
import * as Chartist from 'chartist'
import { ref, onBeforeMount, onMounted, watch, nextTick } from 'vue'
import http from '@/services/http'
import type { DueDateMethodsData, Response } from '@/models/api-generated'

interface Model {
  [key: string]: DueDateMethodsData
}

const chart = ref(null)
const result = ref<Model>()
const style = ref<string>('height:0px;')

const opts: Chartist.BarChartOptions = {
  horizontalBars: true,
  reverseData: true,
  seriesBarDistance: 25,
  chartPadding: {
    right: 20
  },
  axisX: {
    onlyInteger: true,
    labelOffset: {
      x: 0,
      y: 12
    },
  },
  axisY: {
    offset: 100,
  },
}

let barChart: Chartist.BarChart

onBeforeMount(async () => {
  const { data } = await http.get<Response<Model>>('api/dashboard/dueDateMethods')
  result.value = data.data;
})

onMounted(() => {
  barChart = new Chartist.BarChart(chart.value, { series: [] }, opts)
  barChart.on('draw', e => {
    if (e.type === 'bar') {
      const value = Chartist.isMultiValue(e.value)
        ? (e.value as any).x
        : e.value as number

      new Chartist.Svg(
        'text',
        {
          x: e.x2 + 4,
          y: e.y1,
          'dominant-baseline': 'central',
        },
        undefined,
        e.group,
      ).text(value.toString())
    }
  })
})

watch(result, (data, _) => {
  if (!data) return

  const height = Object.keys(data).length * 128
  style.value = `height:${height}px;`

  nextTick(() => {
    const labels: string[] = []
    const ontime: number[] = []
    const approaching: number[] = []
    const critical: number[] = []
    const overdue: number[] = []

    for (const [key, value] of Object.entries(data)) {
      labels.push(key)
      ontime.push(value.ontime)
      approaching.push(value.approaching)
      critical.push(value.critical)
      overdue.push(value.overdue)
    }

    barChart.update({
      labels, series: [
        { className: 'pie1', data: ontime },
        { className: 'pie2', data: approaching },
        { className: 'pie3', data: critical },
        { className: 'pie4', data: overdue },
      ]
    })
  })
})
</script>

<template>
  <div ref="chart" class="duedate-chart" :style="style"></div>

  <div v-if="!result" class="p-4 text-center">
    <div class="spinner-border text-primary"></div>
  </div>
</template>

<style lang="sass">
.duedate-chart .ct-bar
	stroke-width: 25px
</style>