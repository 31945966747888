<script setup lang="tsx">
import { getRuleStatusText } from '@/services/utils'

const model = defineModel({ default: 0 })
</script>

<template>
	<div class="d-inline-block">
		<i v-if="model == 0" class="far fa-times-circle fs-4" style="color: red"></i>
		<i v-else-if="model == 1" class="fas fa-check-circle fs-4" style="color: #1ee01e"></i>
		<i v-else-if="model == 2" class="far fa-times-circle fs-4" style="color: #505162"></i>
		<i v-else-if="model == 3" class="far fa-solid fa-circle-exclamation fs-4" style="color: blue"></i>
		<span class="label">{{ getRuleStatusText(model) }}</span>
	</div>
</template>

<style lang="sass" scoped>
.label
	position: relative
	left: 10px
	top: -.25rem
</style>
