<script setup lang="tsx">
import type { CustomRuleMetaData, StandardRuleMetaData } from '@/models/api-generated'

import methodEditor from '@/stores/method-editor'
import RuleStatusSelect from '@/components/widgets/RuleStatusSelect.vue'
import RuleStatusIcon from '@/components/widgets/RuleStatusIcon.vue'
import { RuleWillNotApply } from '@/models/enums-generated'

const model = defineModel<StandardRuleMetaData | CustomRuleMetaData>({ required: true })
const editor = methodEditor()
for (let i = 0; i < model.value.sub_rules.length; i++) editor.watchRule(model.value.sub_rules[i])

function isValid(i: number, str: string) {
	return model.value.sub_rules[i].status !== RuleWillNotApply || str
}
</script>

<template>
	<template v-for="(subRule, i) in model.sub_rules" :key="subRule.id">
		<div class="row my-0">
			<div class="col-1"></div>

			<div class="col-5 d-inline-flex align-items-center">
				<div>
					<i class="far fa-plus-square table-cell-icon" style="color: lightgray"></i>
				</div>
				<div>
					{{ subRule.rule.value }}
				</div>
			</div>

			<div class="col align-self-center text-center">
				<RuleStatusIcon v-if="!editor.isEditMode" v-model="model.sub_rules[i].status"></RuleStatusIcon>
				<RuleStatusSelect v-else v-model="model.sub_rules[i].status" class="w-50 d-inline"></RuleStatusSelect>
			</div>
		</div>
		<div v-if="model.sub_rules[i].comment || editor.isEditMode" class="row my-0">
			<div class="col-1"></div>
			<div class="col">
				<label for="method-comment" class="form-label">{{ $t('comment') }}:</label>
				<div v-if="!editor.isEditMode" class="comment m-0" rows="1">{{ model.sub_rules[i].comment }}</div>
				<input
					v-else
					type="text"
					class="form-control"
					:class="{ 'is-invalid': !isValid(i, model.sub_rules[i].comment) }"
					v-model="model.sub_rules[i].comment"
				/>
			</div>
		</div>
		<hr v-if="i < model.sub_rules.length - 1" class="my-0 mx-5" data-wut="yeerum" />
	</template>
</template>

<style lang="sass" scoped>
.row
	background: rgb(248,248,248)

.comment
	border-radius: .25rem
	background-color: rgb(222, 222, 222)
	padding: .5rem
</style>
